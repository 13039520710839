<template>
  <div class="publish-event">
    <div class="content-inner">
      <div class="pushu-header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/web/events' }"
            >寻根动态</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/web/publish-event' }"
            >发布寻根</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <el-form
        ref="upload-form"
        :inline="true"
        :model="uploadForm"
        class="upload-form"
      >
        <el-form-item class="upload-form-item" label="任务主题">
          <el-input v-model="uploadForm.title"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="摘要">
          <el-input v-model="uploadForm.description"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="联系人">
          <el-input v-model="uploadForm.contact"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="联系电话">
          <el-input v-model="uploadForm.telephone"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="电子邮箱">
          <el-input v-model="uploadForm.email"></el-input>
        </el-form-item>
        <!-- <el-form-item class="upload-form-item" label="截止日期">
          <el-input v-model="uploadForm.juanshu" disabled></el-input>
        </el-form-item> -->
        <el-form-item class="upload-form-item" label="悬赏金额">
          <el-input-number v-model="uploadForm.price"></el-input-number>
        </el-form-item>
        <el-form-item class="upload-form-item" label="头图">
          <el-upload
            class="avatar-uploader"
            :action="uploadAPi"
            :data="uploadData"
            :show-file-list="false"
            name="avatar"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="uploadForm.image"
              :src="fillSrc(uploadForm.image)"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <vue-ueditor-wrap
          class="editor"
          v-model="uploadForm.content"
          :config="editorConfig"
          editor-id="editor-demo-01"
        ></vue-ueditor-wrap>
      </el-form>
      <div class="submit-area">
        <el-button
          class="submit-btn"
          size="medium"
          type="primary"
          @click="handleSubmit"
          >确认发布</el-button
        >
        <el-button
          class="cancel-btn"
          size="medium"
          type="primary"
          @click="$router.back()"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  InputNumber,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Upload
} from "element-ui";
import { mapActions } from "vuex";
import { apiRoot } from "@/config";
// import PayDialog from "@/components/web/PayDialog";
import otherApis from "@/apis/other";
import VueUeditorWrap from "vue-ueditor-wrap";

const initForm = () => ({
  title: "",
  description: "",
  contact: "",
  telephone: "",
  email: "",
  price: "",
  content: "",
  image: ""
});

export default {
  name: "Upload",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    "el-breadcrumb": Breadcrumb,
    "el-breadcrumb-item": BreadcrumbItem,
    [InputNumber.name]: InputNumber,
    [VueUeditorWrap.name]: VueUeditorWrap,
    [Upload.name]: Upload
  },
  data() {
    return {
      uploadAPi: `${apiRoot}/user/upload_avatar`,
      uploadForm: initForm(),
      fileList: [],
      uploading: false,
      msg: "",
      editorConfig: {
        UEDITOR_HOME_URL: "/ueditor/",
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "//admin.xn--wbsq9hjz4a3rs.com:9992/ueditor/index",
        minFrameHeight: 400,
        initialFrameHeight: 400
      }
    };
  },
  computed: {
    uploadData() {
      const auth = localStorage.getItem("auth") || "{}";
      const { token } = JSON.parse(auth);
      return { token };
    }
  },
  created() {
    this.uploadForm = initForm();
    this.fetchDetail();
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),

    handleAvatarSuccess(res) {
      const {
        data: { avatar_url }
      } = res;
      this.uploadForm.image = avatar_url;
    },

    fillSrc(src) {
      if (src.indexOf("http") >= 0) return src;
      return `${apiRoot}${src}`;
    },

    async fetchDetail() {
      if (!this.$route.query.id) return;
      console.log("fetch detail", this.$route.query.id);
      this.loading = true;
      try {
        const {
          data: { article_info }
        } = await otherApis.articleDetail({
          id: this.$route.query.id
        });

        this.uploadForm = { ...this.uploadForm, ...article_info };
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    handleBeforeUplaod() {
      this.uploading = true;
    },

    handleUploaded(res) {
      const {
        data: { fileurl },
        msg
      } = res;
      if (fileurl) {
        this.uploadForm.source_file = fileurl;
      } else {
        this.$message.error(msg);
      }
      this.uploading = false;
      // console.log(fileurl);
    },
    handleSubmit() {
      this.$refs["upload-form"].validate(async valid => {
        if (valid) {
          console.log(this.uploadForm);
          try {
            await otherApis.articleAdd(this.uploadForm);
            this.$message.success("提交成功，请等待管理员审核");
            this.$router.back();
          } catch (error) {
            console.log("error", error);
            // this.$refs["pay-dialog"].show();
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

//
<style lang="scss">
.upload-form-item {
  margin-bottom: 30px;

  .el-form-item__label {
    text-align: left;
    font-size: 12px;
    color: #333;

    &::after {
      content: "：";
    }
  }
}
</style>

<style lang="scss" scoped>
.publish-event {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  // padding: 20px;
  min-height: calc(100% - 50px);
  box-sizing: border-box;
  background-color: #fff;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .content-inner {
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    border-radius: 4px;
    // padding: 0 20px;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 20px;
  }

  .pushu-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;
    }
  }

  .upload-form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding: 0 20px;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .upload-form-item {
      flex: none;
      width: 32.4%;
      display: flex;
      flex-flow: column nowrap;

      .el-form-item__label {
        text-align: left;
      }
    }
  }

  .upload-input {
    padding: 20px;
    padding-top: 0;
    margin-bottom: 0;
    display: flex;
    position: relative;
    flex-flow: column wrap;
    // align-items: center;
    .upload-btn {
      background-color: #148bfa;
    }
    .upload-tips {
      margin-left: 5px;
      color: #666666;
      font-size: 14px;
    }
  }

  .submit-area {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .editor {
    // height: 600px;
  }
}
</style>
